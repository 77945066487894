import axios, { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { compile } from "path-to-regexp";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { EventStreamContentType, fetchEventSource } from "@megaron/fetch-event-source";
import { HttpAction, HttpService, HttpSseAction } from "@megaron/http-service";
import { logger } from "@megaron/logger";
import { Failure, Ok, Result } from "@megaron/result";
import { sanitizeError } from "@megaron/utils";
import { newUuid } from "@megaron/uuid";

export type CommonErrors = "Unauthorized" | "InternalServerError" | "ConnectionError";

export type HttpServiceClient<TSchema extends HttpService> = {
  [name in keyof TSchema]: TSchema[name] extends HttpAction<
    infer TValue,
    infer TError,
    infer TBody,
    infer TParams,
    infer TQs,
    infer TAuth
  >
    ? HttpServiceClientAction<TValue, TError | CommonErrors, TBody, TParams, TQs>
    : TSchema[name] extends HttpSseAction<
        infer TValue,
        infer TError,
        infer TBody,
        infer TParams,
        infer TQs,
        infer TAuth
      >
    ? HttpServiceClientSseAction<TValue, TError | CommonErrors, TBody, TParams, TQs>
    : never;
};

type ActionInput<TBody, TParams, TQs> = TBody & TParams & TQs & ActionOptions;

type ActionOptions = {
  httpOptions?: {
    withCredentials?: boolean;
    headers?: Record<string, string>;
    onRawResponse?: (response: AxiosResponse) => Promise<void> | void;
  };
};

export type HttpServiceClientAction<TValue, TError, TBody, TParams, TQs> = (
  input: ActionInput<TBody, TParams, TQs>,
) => HttpServiceClientActionResult<TValue, TError>;

type HttpServiceClientActionResult<TValue, TError> = Promise<Ok<TValue> | Failure<TError>> & {
  unwrap: () => Promise<TValue>;
};

export type HttpServiceClientSseAction<TValue, TError, TBody, TParams, TQs> = (
  input: ActionInput<TBody, TParams, TQs>,
  handler?: (result: Ok<TValue> | Failure<TError>) => void,
) => {
  stop: () => void;
  observable: Observable<Ok<TValue> | Failure<TError>>;
  statusObservable: Observable<boolean>;
  getStatus: () => boolean;
};

class RetriableError extends Error {}
class FatalError extends Error {}

export const HttpServiceClient = <TSchema extends HttpService>(
  getHost: string | (() => Promise<string> | string),
  schema: TSchema,
  getAuthHeader?: () => Promise<string | undefined | null> | string | undefined | null,
  testArgument?: string,
): HttpServiceClient<TSchema> => {
  const clientSessionUuid = newUuid();

  return _.mapValues(
    schema,
    (actionSchema, actionName) => (input: ActionInput<unknown, unknown, unknown>, handler?: any) => {
      const serializedParams = actionSchema.paramsSerializer?.serialize(input) ?? undefined;
      const serializedQuery = actionSchema.qsSerializer?.serialize(input) ?? undefined;
      const serializedBody = actionSchema.bodySerializer?.serialize(input) ?? undefined;

      const url = compile(actionSchema.path)(serializedParams ?? {});

      const getHeaders = async () => {
        const authHeader = getAuthHeader && (await getAuthHeader());

        const headers: Record<string, string> = {
          ...input.httpOptions?.headers,
          "Client-Session-Uuid": clientSessionUuid,
        };

        if (authHeader) headers["Authorization"] = authHeader;
        if (testArgument) headers["Test-Argument"] = testArgument;
        return headers;
      };
      const host = typeof getHost === "function" ? getHost() : getHost;

      if (!actionSchema.sse) {
        const resultPromise: Promise<Ok<any> | Failure<any>> = (async () => {
          try {
            const r = await axios.request({
              method: actionSchema.method,
              url,
              baseURL: await host,
              data: serializedBody,
              params: serializedQuery,
              headers: await getHeaders(),
              withCredentials: input.httpOptions?.withCredentials,
            });

            if (input.httpOptions?.onRawResponse) await input.httpOptions.onRawResponse(r);

            const constructResultFromUnwrappedResponse = () => {
              const isOk = r.headers["is-ok"] === "true";
              if (isOk) return Ok(r.data);
              else return Failure(r.data);
            };

            const rawResult = actionSchema.resultWrapper
              ? (r.data as Result<any, any>)
              : constructResultFromUnwrappedResponse();

            if (rawResult.isOk) {
              const valueDeserializationResult = actionSchema.valueSerializer.deserialize(rawResult.value);
              if (valueDeserializationResult.isFailure) {
                logger.error({
                  message: `Failed to deserialize value in ${actionName}`,
                  serializerError: valueDeserializationResult.error,
                  value: rawResult.value,
                });
                return Failure("ConnectionError");
              }

              return valueDeserializationResult;
            } else {
              const errorDeserializationResult = actionSchema.errorSerializer.deserialize(rawResult.error);
              if (errorDeserializationResult.isFailure) {
                logger.error({
                  message: `Failed to deserialize error in ${actionName}`,
                  serializerError: errorDeserializationResult.error,
                  error: rawResult.error,
                });
                return Failure("ConnectionError");
              }

              return Failure(errorDeserializationResult.value);
            }
          } catch (e) {
            if ((e as AxiosError)?.response?.status === 401) {
              logger.debug({ message: "Client request unauthenticated", action: actionName, error: sanitizeError(e) });
              return Failure("Unauthorized");
            } else if ((e as AxiosError)?.response?.status === 403) {
              logger.debug({ message: "Client request permission denied", error: sanitizeError(e) });
              return Failure("Unauthorized");
            }
            if ((e as AxiosError)?.response?.status === 500) {
              logger.debug({
                message: "Client request failed (Internal server error)",
                action: actionName,
                error: sanitizeError(e),
              });
              return Failure("InternalServerError");
            } else {
              logger.debug({ message: "Client request failed", error: sanitizeError(e) });
              return Failure("ConnectionError");
            }
          }
        })();

        const unwrap = async () => {
          const result = await resultPromise;
          if (result.isFailure) {
            const msg = `Failed to unwrap result of ${actionName}: ${result.error}`;
            logger.error(msg, { error: result.error, action: actionName });
            throw new Error(msg);
          }
          return result.value;
        };

        (resultPromise as HttpServiceClientActionResult<any, any>).unwrap = unwrap;

        return resultPromise;
      }

      // SSE
      const statusSubject = new BehaviorSubject<boolean>(false);
      const subject = new Subject();
      if (handler) subject.subscribe(handler);

      const ctrl = new AbortController();

      getHeaders()
        .then(async (headers) => {
          await fetchEventSource(`${await host}${url}`, {
            headers,
            signal: ctrl.signal,
            fetch,
            onmessage: (e) => {
              if (!statusSubject.getValue()) statusSubject.next(true);
              const data = JSON.parse(e.data);
              if (data.isOk) {
                const valueDeserializationResult = actionSchema.valueSerializer.deserialize(data.value).assertOk();
                subject.next(valueDeserializationResult);
              } else {
                const errorDeserializationResult = actionSchema.errorSerializer.deserialize(data.error).assertOk();
                subject.next(Failure(errorDeserializationResult.value));
              }
            },
            async onopen(response) {
              if (!statusSubject.getValue()) statusSubject.next(true);
              if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                return;
              } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                throw new FatalError();
              } else {
                throw new RetriableError();
              }
            },
            onclose() {
              if (statusSubject.getValue()) statusSubject.next(false);

              throw new RetriableError();
            },
            onerror(err) {
              if (statusSubject.getValue()) statusSubject.next(false);
              if (err instanceof FatalError) {
                throw err;
              } else {
                //
              }
            },
          });
        })
        .catch((e) => {
          logger.debug(sanitizeError(e));
          subject.error(Failure("ConnectionError"));
          throw e;
        });

      return {
        stop: async () => {
          ctrl.abort();
        },
        statusObservable: statusSubject.asObservable(),
        getStatus: () => statusSubject.getValue(),
        observable: subject.asObservable(),
      };
    },
  ) as any;
};
