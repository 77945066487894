import { useTheme } from "@emotion/react";
import { appLinkTreesObject } from "@megarax/links";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { NotificationDto, notificationPermissionMap } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";
import { getResourceLink, ResourceId } from "@megaron/resource";

type Props = {
  notification: NotificationDto;
  queryKey: string | string[];
};

export const NotificationTile: React.FC<Props> = (props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const readNotificationMutation = useClientManager("notifications").readNotification().useMutation();

  const notificationGroup = notificationPermissionMap[props.notification.content.type].app;
  const notificationColor = appLinkTreesObject[notificationGroup].color;
  const NotificationIcon = appLinkTreesObject[notificationGroup].Icon;
  const title = props.notification.title;
  const resourceId =
    props.notification.content.vars && "resourceId" in props.notification.content.vars
      ? props.notification.content.vars["resourceId"]
      : null;

  const gradient = `radial-gradient(at 35% top, rgba(255, 255, 255, 0.15), transparent)`;
  const readNotification = () => {
    const { readAt, uuid } = props.notification;
    if (readAt) {
      const link = resourceId ? getResourceLink(resourceId as ResourceId) : null;
      if (link && !link.startsWith("/unknown")) {
        const fullUrl = `${window.location.origin}${link}`;
        window.location.href = fullUrl;
      }
      return;
    }

    readNotificationMutation.mutate(
      { notificationId: uuid },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(props.queryKey);

          const link = resourceId ? getResourceLink(resourceId as ResourceId) : null;
          if (link && !link.startsWith("/unknown")) {
            if (link.startsWith("http") || link.startsWith("https")) {
              window.location.href = link;
            } else {
              navigate(link, { replace: true });
            }
          }
        },
      },
    );
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${props.notification.readAt ? `rgba(${notificationColor}, 0.1)` : notificationColor}`,
        fontSize: "14px",
        fontWeight: "bold",
        padding: "12px 16px",
        minWidth: "16rem",
        background: `${gradient}, ${notificationColor}`,
        color: "rgba(255, 255, 255, 0.7)",
        gap: "8px",
        cursor:
          !props.notification.readAt || (resourceId && getResourceLink(resourceId as ResourceId))
            ? "pointer"
            : "default",
      }}
      onClick={readNotification}
    >
      <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span
          css={{
            fontWeight: props.notification.readAt ? 400 : 700,
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <NotificationIcon css={{ marginRight: "8px", fontSize: "16px" }} />
          {title}
        </span>
        <span css={{ fontSize: "10px", fontWeight: 600 }}>
          {formatDistanceToNowStrict(props.notification.createdAt, {
            locale: pl,
          }) + " temu"}
        </span>
      </div>

      <span css={{ fontSize: "12px", fontWeight: 500 }}>{props.notification.message}</span>
    </div>
  );
};
