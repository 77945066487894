import { Header2, Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { Table } from "@dash/table";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom-v5-compat";

import { notificationPermissionMap } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { formatDate, WebPushTableBody } from "./WebPushTableBody";

export const NotificationDetailsPage = () => {
  const params = useParams<{ uuid: Uuid }>();
  const theme = useTheme();
  const notificationDetailsQuery = useClientManager("notifications")
    .getNotificationLog()
    .useQuery({ notificationUuid: params.uuid ? Uuid(params.uuid) : ("" as Uuid) }, { enabled: !!params.uuid });

  const tableColumns = [
    { label: "broadcastUuid" },
    { label: "Subskrybcja" },
    { label: "Utworzono" },
    { label: "Błąd" },
    { label: "Wysłano" },
    { label: "Wypchnięte" },
    { label: "Odczytane" },
  ];

  return (
    <Page css={{ marginTop: "1rem" }}>
      <PageHeader>{`Powiadomienie ${params.uuid} `}</PageHeader>
      <QuerySkeleton query={notificationDetailsQuery}>
        {(result) => (
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div
              css={{
                overflow: "auto",
                margin: "0 -2rem",
                padding: "0 2rem",
              }}
            >
              <table
                css={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  border: `1px solid ${theme.colors.border}`,
                  borderRadius: "0.75rem",
                  overflow: "hidden",
                  td: {
                    borderBottom: `1px solid ${theme.colors.border}`,
                    padding: "0.75rem 1rem",
                    verticalAlign: "top",
                  },
                  "tr:last-of-type td": {
                    borderBottom: "none",
                  },
                  "td:first-of-type": {
                    color: theme.colors.primary,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    width: "30%",
                  },
                }}
              >
                <tbody>
                  <tr>
                    <td>Odbiorca</td>
                    <td>{result.notification.recipientId}</td>
                  </tr>
                  <tr>
                    <td>Typ</td>
                    <td>{result.notification.content.type}</td>
                  </tr>
                  <tr>
                    <td>Aplikacja</td>
                    <td>{notificationPermissionMap[result.notification.content.type].app}</td>
                  </tr>
                  <tr>
                    <td>Ilość subskrybcji</td>
                    <td>{result.subscriptionCount}</td>
                  </tr>
                  <tr>
                    <td>Wysłanie webpush</td>
                    <td>{formatDate(result.notification.webPushSentAt)}</td>
                  </tr>
                  <tr>
                    <td>Wysłanie maila</td>
                    <td>{formatDate(result.notification.emailSentAt)}</td>
                  </tr>
                  <tr>
                    <td>Wysłanie sms</td>
                    <td>{formatDate(result.notification.smsSentAt)}</td>
                  </tr>

                  <tr>
                    <td>Wysłanie megarax</td>
                    <td>{formatDate(result.notification.sentAt)}</td>
                  </tr>
                  <tr>
                    <td>Przeczytane w megarax</td>
                    <td>{formatDate(result.notification.readAt)}</td>
                  </tr>
                  <tr>
                    <td>Vars</td>
                    <td>{JSON.stringify(result.notification.content.vars)}</td>
                  </tr>
                  <tr>
                    <td>Zgoda email</td>
                    <td>{result.permissions?.email ? "✔" : "✘"}</td>
                  </tr>
                  <tr>
                    <td>Zgoda webpush</td>
                    <td>{result.permissions?.webPush ? "✔" : "✘"}</td>
                  </tr>
                  <tr>
                    <td>Zgoda megarax</td>
                    <td>{result.permissions?.app ? "✔" : "✘"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Header2>Webpush</Header2>
            <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
              <Table columns={tableColumns} css={{ padding: "0 2rem" }}>
                <WebPushTableBody webpushArray={result.webpush} isLoading={notificationDetailsQuery.isLoading} />
              </Table>
            </div>
          </div>
        )}
      </QuerySkeleton>
    </Page>
  );
};
