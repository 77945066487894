import Decimal from "decimal.js";

import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { ProductCodeStatus, productCodeStatusSerializer } from "./codeStatus";

export type ProductCodeDto = {
  id: string;
  status: ProductCodeStatus;
  pointValue: Decimal | null;
  productId: string | null;
};

export const productCodeDtoSerializer = Serializers.object<ProductCodeDto>({
  id: Serializers.string,
  status: productCodeStatusSerializer,
  pointValue: Serializers.decimal.nullable(),
  productId: Serializers.string.nullable(),
});

const checkProductCode = HttpAction({
  path: "/productCodes/id/:codeId",
  method: "get",
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  qsSerializer: Serializers.object<{ key?: string }>({
    key: Serializers.string.optional(),
  }),
  valueSerializer: productCodeDtoSerializer,
  errorSerializer: Serializers.stringOneOf("CodeNotFound"),
});
const redeemProductCode = HttpAction({
  path: "/productCodes/id/:codeId/redeem",
  method: "post",
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    key: Serializers.string,
  }),
  requiresAuth: true as const,
  errorSerializer: Serializers.stringOneOf("InvalidCode", "CodeAlreadyRedeemed", "CodeExpired"),
});
const generateProductCode = HttpAction({
  path: "/productCodes/generate",
  method: "post",
  requiresAuth: true as const,
  valueSerializer: Serializers.object({
    id: Serializers.string,
    key: Serializers.string,
  }),
});

const generateProductCodes = HttpAction({
  path: "/productCodes/generateBatch",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
  }),
  valueSerializer: Serializers.array(
    Serializers.object({
      id: Serializers.string,
      key: Serializers.string,
    }),
  ),
});

const generateProductCodesCsv = HttpAction({
  path: "/productCodes/generateCsv",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object<{
    quantity: number;
    groupNames: string[];
    linkPrefixes?: { public: string; secret: string };
  }>({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
    linkPrefixes: Serializers.object({
      public: Serializers.string,
      secret: Serializers.string,
    }).optional(),
  }),
  valueSerializer: Serializers.object({ fileName: Serializers.string }),
  errorSerializer: Serializers.stringOneOf("FileAlreadyExists"),
});

const generateEncryptedProductCodesCsv = HttpAction({
  path: "/productCodes/generateEncryptedCsv",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    quantity: Serializers.integer,
    groupNames: Serializers.array(Serializers.string),
  }),
  valueSerializer: Serializers.object({ fileName: Serializers.string }),
  errorSerializer: Serializers.stringOneOf("FileAlreadyExists"),
});

const activateProductCode = HttpAction({
  path: "/productCodes/id/:codeId/activate",
  method: "post",
  requiresAuth: true as const,
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  bodySerializer: Serializers.object({
    pointValue: Serializers.decimal,
    productId: Serializers.string,
    expiresAfterDays: Serializers.integer,
    massKg: Serializers.decimal,
  }),
});

const activateProductCodeGroups = HttpAction({
  path: "/productCodeGroups/activate",
  method: "post",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({
    groupNames: Serializers.array(Serializers.string),
    pointValue: Serializers.decimal,
    productId: Serializers.string,
    expiresAfterDays: Serializers.integer,
    massKg: Serializers.decimal,
  }),
});

const expireProductCodes = HttpAction({
  path: "/productCodes/expire",
  method: "post",
  requiresAuth: true as const,
});

const findProductCodes = HttpAction({
  path: "/productCodes/id/:codeId/inspect",
  requiresAuth: true as const,
  method: "get",
  paramsSerializer: Serializers.object({
    codeId: Serializers.string,
  }),
  valueSerializer: Serializers.object({
    id: Serializers.string,
    status: productCodeStatusSerializer,
    pointValue: Serializers.decimal.nullable(),
    productId: Serializers.string.nullable(),
    massKg: Serializers.decimal.nullable(),
    activatedAt: Serializers.datetime.nullable(),
    activatedByUuid: Serializers.string.nullable(),
    generatedAt: Serializers.datetime,
    generatedByUuid: Serializers.string,
    redeemedAt: Serializers.datetime.nullable(),
    redeemedByUuid: Serializers.string.nullable(),
    destroyedAt: Serializers.datetime.nullable(),
    destroyedByUuid: Serializers.string.nullable(),
    expiredAt: Serializers.datetime.nullable(),
    expiredByUuid: Serializers.string.nullable(),
    nominalExpirationDate: dateStringSerializer.nullable(),
    groupNames: Serializers.array(Serializers.string),
  }).array(),
  errorSerializer: Serializers.stringOneOf("CodeNotFound"),
});

const grantProductCodeRedemptions = HttpAction({
  path: "/productCodes/grantRedemptions",
  method: "post",
  bodySerializer: Serializers.object({
    groupNames: Serializers.array(Serializers.string),
    userId: uuidSerializer,
    ...Serializers.object({
      dryRun: Serializers.boolean,
      notifyUser: Serializers.boolean,
    }).partial().fields,
  }),
  valueSerializer: Serializers.object({
    codeCount: Serializers.integer,
    productIds: Serializers.string.array(),
    totalPointValue: Serializers.decimal,
  }),
  requiresAuth: true as const,
  errorSerializer: Serializers.stringOneOf("CodeNotRedeemable", "LimitExceeded", "NoCodesFound"),
});

export const productCodeActions = {
  activateProductCode,
  activateProductCodeGroups,
  checkProductCode,
  generateEncryptedProductCodesCsv,
  generateProductCode,
  generateProductCodes,
  generateProductCodesCsv,
  redeemProductCode,
  expireProductCodes,
  findProductCodes,
  grantProductCodeRedemptions,
};
