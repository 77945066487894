import { RowCellContent, TableBody } from "@dash/table";

import { WebPushNotification } from "@megaron/notifications-contracts";

type Props = {
  webpushArray: WebPushNotification[];
  isLoading: boolean;
};

export const formatDate = (date?: Date | null) =>
  date ? date.toLocaleString("pl-PL", { dateStyle: "short", timeStyle: "short" }) : "-";

export const WebPushTableBody: React.FC<Props> = ({ webpushArray, isLoading }) => {
  if (!isLoading && webpushArray.length === 0) {
    return (
      <tr>
        <td colSpan={7}>
          <div
            css={{
              padding: "1rem",
              border: "1px dashed #ccc",
              borderRadius: "0.75rem",
              textAlign: "center",
              color: "#666",
              fontStyle: "italic",
            }}
          >
            Brak
          </div>
        </td>
      </tr>
    );
  }

  const getTableRowCellsContent = (webpush: WebPushNotification): RowCellContent[] => [
    {
      isLink: true,
      element: <span css={{ paddingLeft: "10px", whiteSpace: "nowrap" }}>{webpush.broadcastUuid}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{webpush.subscriptionUuid}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{formatDate(webpush.createdAt)}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{webpush.error}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{formatDate(webpush.sentAt)}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{formatDate(webpush.pushedAt)}</span>,
    },
    {
      isLink: true,
      element: <span css={{ whiteSpace: "nowrap" }}>{formatDate(webpush.openedAt)}</span>,
    },
  ];

  return (
    <TableBody
      isLoading={isLoading}
      rows={webpushArray.map((webpush) => ({
        uuid: webpush.broadcastUuid,
        cellsContent: getTableRowCellsContent(webpush),
        css: { paddingLeft: "10px" },
      }))}
    />
  );
};
