import { useTheme } from "@emotion/react";
import React from "react";

type DateRange = {
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  startDate?: Date;
  endDate?: Date;
  onChange: (range: DateRange) => void;
  className?: string;
  isDisabled?: boolean;
  variant?: "primary" | "secondary";
};

export const DateRangeFilter: React.FC<Props> = ({
  startDate,
  endDate,
  onChange,
  className,
  isDisabled,
  variant = "primary",
}) => {
  const theme = useTheme();

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date: Date) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  };

  const handleDateChange = (key: "startDate" | "endDate") => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        const [year, month, day] = e.target.value.split("-").map((num) => parseInt(num, 10));
        const base = (key === "startDate" ? startDate : endDate) ?? new Date();
        const selectedDate = new Date(base);
        selectedDate.setFullYear(year, month - 1, day);
        onChange({
          startDate: key === "startDate" ? selectedDate : startDate,
          endDate: key === "endDate" ? selectedDate : endDate,
        });
      }
    };
  };

  const handleTimeChange = (key: "startDate" | "endDate") => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = key === "startDate" ? startDate : endDate;
      if (e.target.value && value) {
        const [hours, minutes] = e.target.value.split(":").map((num) => parseInt(num, 10));
        const selectedTime = new Date(value);
        selectedTime.setHours(hours);
        selectedTime.setMinutes(minutes);
        onChange({
          startDate: key === "startDate" ? selectedTime : startDate,
          endDate: key === "endDate" ? selectedTime : endDate,
        });
      }
    };
  };

  const getDateInputProps = (
    value: Date | undefined,
    onChangeFn: (e: React.ChangeEvent<HTMLInputElement>) => void,
  ) => ({
    value: value ? formatDate(value) : "",
    onChange: onChangeFn,
    type: "date",
    css: {
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      cursor: "pointer",
      backgroundColor: variant === "primary" ? theme.colors.primaryLight : "white",
      color: variant === "secondary" ? theme.colors.primary : "inherit",
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
    },
    disabled: isDisabled ?? false,
  });

  const getTimeInputProps = (
    value: Date | undefined,
    onChangeFn: (e: React.ChangeEvent<HTMLInputElement>) => void,
  ) => ({
    value: value ? formatTime(value) : "",
    onChange: onChangeFn,
    type: "time",
    css: {
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      cursor: "pointer",
      backgroundColor: variant === "primary" ? theme.colors.primaryLight : "white",
      color: variant === "secondary" ? theme.colors.primary : "inherit",
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
    },
    disabled: isDisabled ?? false,
  });

  return (
    <div
      className={className}
      css={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "flex-start",
        "@media(min-width: 768px)": {
          flexWrap: "nowrap",
        },
      }}
    >
      <div css={{ display: "flex", gap: "0.25rem", flexWrap: "nowrap" }}>
        <input {...getDateInputProps(startDate, handleDateChange("startDate"))} />
        <input {...getTimeInputProps(startDate, handleTimeChange("startDate"))} />
      </div>
      <span css={{ color: variant === "secondary" ? theme.colors.primary : "inherit", fontSize: "1.2rem" }}>–</span>
      <div css={{ display: "flex", gap: "0.25rem", flexWrap: "nowrap" }}>
        <input {...getDateInputProps(endDate, handleDateChange("endDate"))} />
        <input {...getTimeInputProps(endDate, handleTimeChange("endDate"))} />
      </div>
    </div>
  );
};
