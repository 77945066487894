import { Table, TableHeadColumn } from "@dash/table";

import { NotificationLog } from "@megaron/notifications-contracts";

import { AllNotificationsTableBody } from "./AllNotificationsTableBody";

type Props = {
  notifications: NotificationLog[];
  className?: string;
  isLoading: boolean;
};

export const AllNotificationsTable: React.FC<Props> = ({ className, notifications, isLoading }) => {
  const tableColumns = [
    { label: "Typ" },
    { label: "Aplikacja" },
    { label: "Odbiorca" },
    { label: "Data" },
    { label: "Kanały" },
    { label: "Subskrypcje" },
    { label: "Wysłane" },
    { label: "Wypchnięte" },
    { label: "Odczytane" },
    { label: "Zgoda: email" },
    { label: "Zgoda: webpush" },
    { label: "Zgoda: app" },
  ];

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table columns={tableColumns} css={{ padding: "0 2rem" }}>
        <AllNotificationsTableBody notifications={notifications} isLoading={isLoading} />
      </Table>
    </div>
  );
};
