import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { alertTypes } from "./alertsMap";
import { webPush } from "./webPush";

export const notificationTypes = [
  "crmInteractionMention",
  "crmRegionAssigned",
  "invoiceThreadMention",
  "taxReimbursementIssued",
  "testWebPush",
  "referralBonusEarned",
  "affiliateCodeRedeemed",
  "productCodeRedemptionsGranted",
  "test",
] as const;

export type NotificationType = (typeof notificationTypes)[number];

const notificationContentSerializers = [
  Serializers.object({
    type: Serializers.stringOneOf("crmInteractionMention"),
    vars: Serializers.object({
      commentValue: Serializers.string,
      customerUuid: Serializers.string,
      interactionResourceId: Serializers.string,
    }),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("crmRegionAssigned"),
    vars: Serializers.object({
      customerName: Serializers.string,
      customerUuid: Serializers.string,
      customerResourceId: Serializers.string,
    }),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("invoiceThreadMention"),
    vars: Serializers.object({
      commentValue: Serializers.string,
      threadUuid: Serializers.string,
      threadCommentResourceId: Serializers.string,
    }),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("taxReimbursementIssued"),
    vars: Serializers.object({
      declarationId: Serializers.string,
    }),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("testWebPush"),
    vars: Serializers.object({}),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("referralBonusEarned"),
    vars: Serializers.object({}),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("affiliateCodeRedeemed"),
    vars: Serializers.object({}),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("productCodeRedemptionsGranted"),
    vars: Serializers.object({
      notificationId: Serializers.string,
      codeCount: Serializers.integer,
      productIds: Serializers.string.array(),
      totalPointValue: Serializers.decimal,
    }),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("test"),
    vars: Serializers.object({}),
  }),
];

const test = notificationContentSerializers.map((s) => s.fields.type);

export const notificationContentSerializer = Serializers.first(...notificationContentSerializers);

export type NotificationContent = SerializerValue<typeof notificationContentSerializer>;

export const notificationDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  recipientId: Serializers.string,
  title: Serializers.string,
  message: Serializers.string,
  emailSentAt: Serializers.datetime.nullable(),
  smsSentAt: Serializers.datetime.nullable(),
  webPushSentAt: Serializers.datetime.nullable(),
  sentAt: Serializers.datetime.nullable(),
  readAt: Serializers.datetime.nullable(),
  createdAt: Serializers.datetime,
  content: notificationContentSerializer,
});

export type NotificationDto = SerializerValue<typeof notificationDtoSerializer>;

export const notificationPermissionsSerializer = Serializers.object({
  email: Serializers.boolean,
  webPush: Serializers.boolean,
  app: Serializers.boolean,
});

export const notificationLogSerializer = Serializers.object({
  uuid: uuidSerializer,
  recipientId: Serializers.string,
  title: Serializers.string,
  message: Serializers.string,
  emailSentAt: Serializers.datetime.nullable(),
  smsSentAt: Serializers.datetime.nullable(),
  webPushSentAt: Serializers.datetime.nullable(),
  sentAt: Serializers.datetime.nullable(),
  readAt: Serializers.datetime.nullable(),
  createdAt: Serializers.datetime,
  type: Serializers.stringOneOf(...notificationTypes),
  app: Serializers.string,
  subscriptionAmount: Serializers.integer,
  openedWebpushCount: Serializers.integer,
  pushedWebpushCount: Serializers.integer,
  sentWebpushCount: Serializers.integer,
  permissions: notificationPermissionsSerializer,
});

export type NotificationLog = SerializerValue<typeof notificationLogSerializer>;

export const alertSerializer = Serializers.object({
  uuid: uuidSerializer,
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...alertTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  metric: Serializers.integer.nullable(),
  createdAt: Serializers.datetime,
});

export type AlertObject = SerializerValue<typeof alertSerializer>;

export const getNotificationsSerializer = Serializers.object({
  notifications: Serializers.array(notificationDtoSerializer),
  alerts: Serializers.array(alertSerializer),
  count: Serializers.integer,
  unreadCount: Serializers.integer,
});

export type NotificationsListObject = SerializerValue<typeof getNotificationsSerializer>;

export const permissionSerializer = Serializers.object({
  type: Serializers.stringOneOf(...notificationTypes),
  isEmail: Serializers.boolean,
  isWebPush: Serializers.boolean,
  isApp: Serializers.boolean,
});

export const webPushNotificationPaylodSerializer = Serializers.object({
  title: Serializers.string,
  body: Serializers.string,
  image: Serializers.string.nullable(),
  link: Serializers.string.nullable(),
  userId: Serializers.string,
  app: Serializers.string,
  actions: Serializers.array(
    Serializers.object({
      title: Serializers.string,
      action: Serializers.stringOneOf("openLink"),
    }),
  ),
});

export type WebPushNotificationPayload = SerializerValue<typeof webPushNotificationPaylodSerializer>;

export const webPushNotificationSerializer = Serializers.object({
  broadcastUuid: Serializers.string,
  notificationUuid: uuidSerializer.nullable(),
  subscriptionUuid: uuidSerializer,
  createdAt: Serializers.datetime,
  sentAt: Serializers.datetime.nullable(),
  openedAt: Serializers.datetime.nullable(),
  pushedAt: Serializers.datetime.nullable(),
  error: Serializers.string.nullable(),
  payload: webPushNotificationPaylodSerializer,
});

export type WebPushNotification = SerializerValue<typeof webPushNotificationSerializer>;

export const produceNotificationSerializer = Serializers.object({
  recipientId: Serializers.string,
  content: notificationContentSerializer,
  title: Serializers.string,
  message: Serializers.string,
});

export const produceAlertSerializer = Serializers.object({
  recipientId: Serializers.string,
  type: Serializers.stringOneOf(...alertTypes),
  message: Serializers.string,
  link: Serializers.string.nullable(),
  metric: Serializers.integer.nullable(),
});

export const notifications = {
  produceNotification: HttpAction({
    path: "/notification",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: produceNotificationSerializer,
    valueSerializer: notificationDtoSerializer,
  }),

  getNotifications: HttpAction({
    path: "/notification",
    method: "get",
    qsSerializer: Serializers.object({
      app: Serializers.string,
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: getNotificationsSerializer,
  }),

  getNotificationLogs: HttpAction({
    path: "/notification/logs",
    method: "get",
    qsSerializer: Serializers.object({
      startDate: Serializers.datetime,
      endDate: Serializers.datetime,
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: Serializers.object({ items: notificationLogSerializer.array(), count: Serializers.integer }),
  }),

  getNotificationLog: HttpAction({
    path: "/notification/:notificationUuid/log",
    method: "get",
    paramsSerializer: Serializers.object({
      notificationUuid: uuidSerializer,
    }),
    requiresAuth: true as const,
    valueSerializer: Serializers.object({
      notification: notificationDtoSerializer,
      webpush: webPushNotificationSerializer.array(),
      permissions: notificationPermissionsSerializer,
      subscriptionCount: Serializers.integer,
    }),
  }),

  readNotification: HttpAction({
    path: "/notification/:notificationId/read",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      notificationId: Serializers.string,
    }),
  }),

  deactivateNotification: HttpAction({
    path: "/notification/:notificationId/deactivate",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      notificationId: Serializers.string,
    }),
  }),

  getPermissions: HttpAction({
    path: "/permissions",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(permissionSerializer),
  }),

  savePermission: HttpAction({
    path: "/permission",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      type: Serializers.stringOneOf(...notificationTypes),
      isEmail: Serializers.boolean,
      isWebPush: Serializers.boolean,
      isApp: Serializers.boolean,
    }),
  }),

  produceTestNotification: HttpAction({
    path: "/testNotification",
    method: "post",
    requiresAuth: true as const,
    valueSerializer: notificationDtoSerializer,
  }),

  produceAlert: HttpAction({
    path: "/alert",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: produceAlertSerializer,
    valueSerializer: alertSerializer,
  }),
};
