import { DescriptionField, DescriptionList } from "@dash/description-list";
import { Button, Chip, TextField } from "@dash/form";
import { Header3, Header4, Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { Tile, TileStripe } from "@dash/tile";
import { useTheme } from "@emotion/react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom-v5-compat";

import { DocType } from "@megaron/docs-contracts";
import { ProductCodeStatus } from "@megaron/loyalty-points-contracts";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";

export const InspectProductCodeView: React.FC = () => {
  // read code from route param
  const { codeId } = useParams<{ codeId: string }>();
  const navigate = useNavigate();

  const [input, setInput] = React.useState(codeId ?? "");

  const handleInput = (val: string) => {
    setInput(val.replace(/-/g, "").substring(0, 6).toUpperCase());
  };

  const onSubmit = () => {
    navigate(`/specjalista/product-codes/inspect-code/${input}`);
  };

  const error = codeId && codeId.length < 6 ? "Kod za krótki" : undefined;

  return (
    <Page>
      <PageHeader>Sprawdź kod</PageHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          Podaj pierwsze 6 znaków kodu produktowego
          <TextField label="Kod" value={input} onChange={handleInput} error={error} />
          <div>
            <Button onClick={onSubmit}>Sprawdź</Button>
          </div>
        </div>
        {codeId && <ProductCodeDetails codeId={codeId} />}
      </div>
    </Page>
  );
};

const ProductCodeDetails: React.FunctionComponent<{
  codeId: string;
}> = (props) => {
  const query = useClientManager("loyaltyPoints").findProductCodes().useQuery({ codeId: props.codeId });
  const theme = useTheme();

  const statusLabelMap: { [key in ProductCodeStatus]: React.ReactNode } = {
    active: <Chip variant="primary">Aktywny</Chip>,
    blank: <Chip variant="secondary">Nieaktywny</Chip>,
    destroyed: (
      <Chip variant="primary" color={theme.colors.danger}>
        Usunięty
      </Chip>
    ),
    expired: (
      <Chip variant="primary" color={theme.colors.danger}>
        Przeterminowany
      </Chip>
    ),
    redeemed: <Chip variant="secondary">Wykorzystany</Chip>,
  };

  if (query.data?.length === 0) return <div>Nie znaleziono</div>;

  return (
    <QuerySkeleton query={query}>
      {(codes) =>
        codes.map((code) => (
          <Tile gap="1rem">
            <Header3 css={{ fontFamily: "monospace" }}>{code.id}</Header3>
            <DescriptionList>
              <DescriptionField label="Status">{statusLabelMap[code.status]}</DescriptionField>
              <DescriptionField label="Produkt">{code.productId}</DescriptionField>
              <DescriptionField label="Masa">{code.massKg?.toFixed(1)} kg</DescriptionField>
              <DescriptionField label="Grupy">{code.groupNames.join(", ")}</DescriptionField>
              <DescriptionField label="Data generacji">{code.generatedAt?.toLocaleString()} </DescriptionField>
              <DescriptionField label="Data aktywacji">{code.activatedAt?.toLocaleString()}</DescriptionField>
              <DescriptionField label="Termin ważności">{code.nominalExpirationDate} </DescriptionField>
              <DescriptionField label="Data przeterminowania">{code.expiredAt?.toLocaleString()} </DescriptionField>
              <DescriptionField label="Data wykorzystania">{code.redeemedAt?.toLocaleString()}</DescriptionField>
              <DescriptionField label="Wykorzystany przez">
                {code.redeemedByUuid && <RedeemedByTile customerId={code.redeemedByUuid} />}
              </DescriptionField>
            </DescriptionList>
          </Tile>
        ))
      }
    </QuerySkeleton>
  );
};

const RedeemedByTile: React.FunctionComponent<{
  customerId: string;
}> = (props) => {
  const docId = ResourceId<DocType>("customer", props.customerId);
  const query = useClientManager("docs")
    .retrieveDocs()
    .useQuery({ docIds: [docId] });

  return (
    <Link to={`/crm/customers/id/${props.customerId}`}>
      <QuerySkeleton query={query}>{(docs) => <Tile stripe={<TileStripe />}>{docs[0]?.name}</Tile>}</QuerySkeleton>
    </Link>
  );
};
