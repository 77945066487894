import { RowCellContent, TableBody } from "@dash/table";

import { NotificationLog } from "@megaron/notifications-contracts";

type Props = {
  notifications: NotificationLog[];
  isLoading: boolean;
};

export const AllNotificationsTableBody: React.FC<Props> = ({ notifications, isLoading }) => {
  const getChannels = (n: NotificationLog) => {
    const list: string[] = [];
    if (n.emailSentAt) list.push("email");
    if (n.webPushSentAt || n.sentWebpushCount > 0) list.push("webpush");
    if (n.smsSentAt) list.push("sms");
    if (n.title && n.title.trim() !== "") list.push("megarax");
    return list.join(", ");
  };

  const formatDate = (date?: string | Date | null) =>
    date ? new Date(date).toLocaleString("pl-PL", { dateStyle: "short", timeStyle: "short" }) : "-";

  const getRowLink = (uuid: string) => {
    return `/dev/notifications/uuid/${uuid}`;
  };

  const getTableRowCellsContent = (notification: NotificationLog): RowCellContent[] => [
    {
      isLink: true,
      element: <span css={{ paddingLeft: "10px", whiteSpace: "nowrap" }}>{notification.type}</span>,
    },
    {
      isLink: false,
      element: <span css={{ whiteSpace: "nowrap" }}>{notification.app}</span>,
    },
    {
      isLink: false,
      element: <span css={{ whiteSpace: "nowrap" }}>{notification.recipientId}</span>,
    },
    {
      isLink: false,
      element: <span css={{ whiteSpace: "nowrap" }}>{formatDate(notification.createdAt)}</span>,
    },
    {
      isLink: false,
      element: <span>{getChannels(notification)}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.subscriptionAmount}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.sentWebpushCount}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.pushedWebpushCount}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.openedWebpushCount}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.permissions?.email ? "✔" : "✘"}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.permissions?.webPush ? "✔" : "✘"}</span>,
    },
    {
      isLink: false,
      element: <span>{notification.permissions?.app ? "✔" : "✘"}</span>,
    },
  ];

  return (
    <TableBody
      isLoading={isLoading}
      rows={notifications.map((notification) => ({
        linkTo: getRowLink(notification.uuid),
        uuid: notification.uuid,
        cellsContent: getTableRowCellsContent(notification),
        css: { paddingLeft: "10px" },
      }))}
    />
  );
};
