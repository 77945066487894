import { Button } from "@dash/form";
import { QuerySkeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";
import { getAppColor } from "@megarax/home";
import React, { useContext } from "react";

import { IamAuthContext } from "@megaron/auth-react";
import { notificationPermissionMap, NotificationType } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";

import { PermissionTile } from "./PermissionTile";

type Props = {
  notificationsQueryKey: string | string[];
};

export const NotificationsPermissions: React.FC<Props> = (props) => {
  const theme = useTheme();
  const auth = useContext(IamAuthContext);
  const userAttributes = auth.iamUser?.attributes;

  const permissionsQuery = useClientManager("notifications").getPermissions().useQuery({});
  const testNotificationMutation = useClientManager("notifications").produceTestNotification().useMutation();

  const groupName: Record<string, string> = {
    invoices: "Faktury",
    crm: "CRM",
    external: "Pozostałe",
    plm: "PLM",
    iam: "IAM",
    loyaltyAdmin: "Specjalista",
  };

  const groupPermissions = (permissions: any[]) => {
    const grouped: Record<string, any[]> = {};

    permissions.forEach((permission) => {
      if (permission.type === "test" || permission.type === "testWebPush") {
        return;
      }

      const permissionConfig = notificationPermissionMap[permission.type as NotificationType];

      if (!permissionConfig) {
        return;
      }

      const hasAccess = permissionConfig.targetAudienceAttributes.some((attr) => userAttributes?.includes(attr));

      if (!hasAccess) {
        return;
      }

      const app = permissionConfig.app;
      if (!grouped[app]) {
        grouped[app] = [];
      }
      grouped[app].push(permission);
    });

    return grouped;
  };

  const testNotification = () => {
    testNotificationMutation.mutate({});
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <QuerySkeleton query={permissionsQuery}>
        {(permissions) => {
          if (!permissions) return null;

          const groupedPermissions = groupPermissions(permissions);

          return (
            <div css={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "400px" }}>
              {Object.entries(groupedPermissions).map(([group, permissions]) => (
                <div key={group} css={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <GroupHeader name={groupName[group]} color={getAppColor(group, theme)} />
                  <div css={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    {permissions.map((permission, i) => (
                      <PermissionTile
                        key={i}
                        permission={permission}
                        permissionsQuery={permissionsQuery.key}
                        notificationsQueryKey={props.notificationsQueryKey}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
        }}
      </QuerySkeleton>

      <Button onClick={testNotification}>Testowe powiadomienie webpush</Button>
    </div>
  );
};

type GroupHeaderProps = {
  name: string;
  color: string;
};

const GroupHeader: React.FC<GroupHeaderProps> = (props) => {
  return (
    <div css={{ display: "flex", gap: "1rem", alignItems: "center", color: props.color }}>
      <span css={{ fontWeight: 700, fontSize: "1.25rem", flexShrink: 0 }}>{props.name}</span>
      <div css={{ height: "1px", width: "100%", backgroundColor: props.color, border: "none" }} />
    </div>
  );
};
